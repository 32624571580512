import { Controller } from "stimulus";
import { formatLastSeen } from "../custom/functions/common.js";

// Connects to data-controller="drag-map"
export default class extends Controller {
  connect() {
    try {
      function mapLayer(mapStyle, latitude, longitude, zoom) {
        // Create the map layer & style
        const mapBoxToken =
          "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

        mapboxgl.accessToken = mapBoxToken;
        map = new mapboxgl.Map({
          container: "draggableMarkerMap", // container ID
          style: mapStyle, // style URL
          center: [longitude, latitude], // starting position [lng, lat]
          attribution: "Nvirosense",
          zoom: zoom, // starting zoom
          // pitch: 70,
          cooperativeGestures: true,
          attributionControl: false,
        });

        map.on("load", () => {
          map.addSource("dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          });
          map.addLayer(
            {
              id: "hillshading",
              source: "dem",
              type: "hillshade",
            },
            // Insert below land-structure-polygon layer,
            // where hillshading sits in the Mapbox Streets style.
            "land-structure-polygon"
          );
        });

        //End: Create the map layer & style
      }

      function markerPopup(
        latitude,
        longitude,
        deviceName,
        deviceType,
        deviceLastReceived
      ) {

        if (deviceLastReceived == 'No Readings'){

          let fullCoords = latitude.toFixed(8) + "°<br>" + longitude.toFixed(8) + "°";

          // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
          const mapboxApi =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            longitude +
            "," +
            latitude +
            ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
          fetch(mapboxApi)
            .then((response) => response.json())
            .then((data) => {
              // Extract the place_name property and store it in a variable
              const placeName = data.features[0].place_name;

              // Popup
              let popup = new AnimatedPopup({
                anchor: "bottom",
                offset: 25,
                openingAnimation: {
                  duration: 600,
                  easing: "easeOutElastic",
                  transform: "scale",
                },
                closingAnimation: {
                  duration: 200,
                  easing: "easeInBack",
                  transform: "scale",
                },
              }).setHTML(`
              <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
              <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
              <colgroup>
              <col style="width: 90px">
              <col style="width: 80px">
              </colgroup>
              <thead>
                <tr>
                  <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
                  <th class="popup-nvirosense-73oq">${placeName}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
                  <td class="popup-nvirosense-73oq">No Readings</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
                  <td class="popup-nvirosense-73oq">No Readings</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
                  <td class="popup-nvirosense-73oq">${fullCoords}</td>
                </tr>
              </tbody>
              </table>
              `);
              // End: Popup

              popup.setLngLat([longitude, latitude]).addTo(map);
              marker.setPopup(popup);

              console.log("popup");

              // console.log(placeName);
            })
            .catch((error) => {
              // Handle any errors that occur during the fetch request
              // console.error(error);
            });


        } else {

          let fullCoords = latitude.toFixed(8) + "°<br>" + longitude.toFixed(8) + "°";
          let date = new Date(deviceLastReceived);
          let unixTime = date.getTime();
          console.log(latitude);

          const formatDate = deviceLastReceived.slice(0, 10);
          const formatTime = deviceLastReceived.slice(11, 19);

          let elapsedTime = formatLastSeen(unixTime);

          // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
          const mapboxApi =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            longitude +
            "," +
            latitude +
            ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
          fetch(mapboxApi)
            .then((response) => response.json())
            .then((data) => {
              // Extract the place_name property and store it in a variable
              const placeName = data.features[0].place_name;

              // Popup
              let popup = new AnimatedPopup({
                anchor: "bottom",
                offset: 25,
                openingAnimation: {
                  duration: 600,
                  easing: "easeOutElastic",
                  transform: "scale",
                },
                closingAnimation: {
                  duration: 200,
                  easing: "easeInBack",
                  transform: "scale",
                },
              }).setHTML(`
              <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
              <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
              <colgroup>
              <col style="width: 90px">
              <col style="width: 80px">
              </colgroup>
              <thead>
                <tr>
                  <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
                  <th class="popup-nvirosense-73oq">${placeName}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
                  <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
                  <td class="popup-nvirosense-73oq">${elapsedTime}</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
                  <td class="popup-nvirosense-73oq">${fullCoords}</td>
                </tr>
              </tbody>
              </table>
              `);
              // End: Popup

              popup.setLngLat([longitude, latitude]).addTo(map);
              marker.setPopup(popup);

              console.log("popup");

              // console.log(placeName);
            })
            .catch((error) => {
              // Handle any errors that occur during the fetch request
              // console.error(error);
            });


        }



        console.log("runner 1");

        // // Remove the navigation control from the map
        // map.removeControl(navControl);

        // // Add the navigation control to the map
        // map.addControl(navControl);

        // End: API to GET adress
      }

      function mapLogic(latitude, longitude) {
        if (
          latitude == 0 ||
          longitude == 0 ||
          longitude == "undifined" ||
          latitude == "undifined" ||
          longitude == null ||
          latitude == null
        ) {
          mapLayer(mapStyle, -28.5, 25.541563, 5);
          marker.setLngLat([25.541563, -29.116376]).addTo(map);
        } else {
          mapLayer(mapStyle, latitude, longitude, 13);
          marker.setLngLat([longitude, latitude - 0.01]).addTo(map);
        }
      }

      function onDragEnd() {
        const lngLat = marker.getLngLat();

        map.flyTo({ center: [lngLat.lng, lngLat.lat + 0.01] });

        markerPopup(
          lngLat.lat,
          lngLat.lng,
          deviceName,
          deviceType,
          deviceLastReceived
        );

        // console.log(`Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`);

        const csrfToken = document.querySelector(
          'meta[name="csrf-token"]'
        ).content;

        fetch(`/devices/${deviceId}/update_draggable_marker`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify({ drag_lat: lngLat.lat, drag_lon: lngLat.lng }),
        })
          // .then(response => {
          //   // do something with response, such as redirect user to thank you page
          // })
          .catch((error) => console.error(error));

        console.log("Correct");
      }

      let popup;
      let deviceName;
      let deviceLastReceived;
      let deviceType;
      let latitude;
      let longitude;
      let map;
      var navControl = new mapboxgl.NavigationControl();
      let mapStyle = "mapbox://styles/mapbox/outdoors-v12";
      const marker = new mapboxgl.Marker({ draggable: true });

      let idPathArray = window.location.pathname.split("/");
      let deviceId = idPathArray[2];
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;

      fetch(`/devices/${deviceId}/get_draggable_marker`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          if (
            data !== null ||
            data !== undefined
          ) {
            latitude = data.drag_lat;
            longitude = data.drag_lon;
            deviceName = data.device_name;
            deviceType = data.device_type;

            if (data.device_received_at !== null){
              deviceLastReceived = data.device_received_at;
            } else {
              deviceLastReceived = 'No Readings';
            }

            markerPopup(
              latitude,
              longitude,
              deviceName,
              deviceType,
              deviceLastReceived
            );



          mapLogic(latitude, longitude);
          console.log("data");

          map.removeControl(navControl);
          map.addControl(navControl);
        }
        })

        .catch((error) => {
          console.error("Error:", error);
        });

      marker.on("dragend", onDragEnd);

    } catch (error) {
      console.log(error);
    }
  }
}
