/*
To display graph in erb:

<div class="<%= @device.name %>-wind-graph-heading">
  <div class="row">
    <div class="col-sm-6 mx-auto text-center">
      <text x="1423" text-anchor="middle" class="highcharts-title" data-z-index="4" style="color:#333333;font-size:18px;fill:#333333;" y="24" aria-hidden="true">Windspeed & Direction</text>
      <br>
      <text x="1423" text-anchor="middle" class="highcharts-subtitle" data-z-index="4" style="color:#666666;fill:#666666; font-size: 12px;" y="44" aria-hidden="true">Weather Station Using S2100 Data-Logger</text>
    </div>
  </div>
</div>
<div>
<div id="<%= @device.name %>-wind-graph" data-controller="wind-graph"></div>
*/
import { Controller } from "stimulus";

// Connects to data-controller="wind-graph"
export default class extends Controller {
  connect() {
    try {
      const data = [];
      const maximumReadingAmount = 60;
      const readingAmount = gon.wind_graphs.length;
      const reversedGraphs = gon.wind_graphs.slice().reverse();
      const utcOffset = 2 * 60 * 60 * 1000; // UTC offset in milliseconds

      if (readingAmount < maximumReadingAmount) {
        for (let i = 0; i < readingAmount; i++) {
          const windSpeed = parseFloat(reversedGraphs[i].wind_speed);
          const windDirection = parseFloat(reversedGraphs[i].wind_direction);
          const date = new Date(reversedGraphs[i].received_at);
          date.setTime(date.getTime() + utcOffset); // Adjust the date by adding the UTC offset
          data.push([date.getTime(), windSpeed, windDirection]);
        }
      } else {
        for (let i = 0; i < maximumReadingAmount; i++) {
          const windSpeed = parseFloat(reversedGraphs[gon.wind_graphs.length - maximumReadingAmount + i].wind_speed);
          const windDirection = parseFloat(reversedGraphs[gon.wind_graphs.length - maximumReadingAmount + i].wind_direction);
          const date = new Date(reversedGraphs[gon.wind_graphs.length - maximumReadingAmount + i].received_at);
          date.setTime(date.getTime() + utcOffset); // Adjust the date by adding the UTC offset
          data.push([date.getTime(), windSpeed, windDirection]);
        }
      }


      Highcharts.chart(gon.wind_graphs[0].device_type + "-wind-graph", {
        title: {
          text: " ",
        },
        credits: {
          enabled: false,
        },

        subtitle: {
          align: "left",
        },

        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%e. %b",
            week: "%e. %b",
            month: "%b '%y",
            year: "%Y",
          },
          offset: 40,
        },

        yAxis: {
          title: {
            text: "Wind speed (m/s)",
          },
        },

        series: [
          {
            type: "windbarb",
            data: data,
            name: "Wind",
            color: Highcharts.getOptions().colors[1],
            showInLegend: false,
            tooltip: {
              valueSuffix: " m/s",
            },
          },
          {
            type: "area",
            data: data.map((point) => [point[0], point[1]]),
            color: Highcharts.getOptions().colors[0],
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.25)
                    .get(),
                ],
              ],
            },
            name: "Wind speed",
            id: "areaseries",
            tooltip: {
              valueSuffix: " m/s",
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  }
}
