import { Controller } from "stimulus";
import { formatLastSeen } from "../custom/functions/common.js";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

// Connects to data-controller="tracking-map"
export default class extends Controller {
  connect() {
    try {
      console.log("running");
      function geoFenceLimits() {
        // Get the selected radio button
        const selectedRadioButton = document.querySelector(
          'input[name="geo-options"]:checked'
        );

        // Check the value of the selected radio button
        if (selectedRadioButton) {
          const selectedValue = selectedRadioButton.value;

          // Perform actions based on the selected value
          if (selectedValue === "if-leave") {
            // Code to run when option 1 is selected
            console.log("can't leave");
          } else if (selectedValue === "if-enter") {
            // Code to run when option 2 is selected
            console.log("can't enter");
          }
        }
        // Add event listener to the radio buttons
        const radioButtons = document.querySelectorAll(
          'input[name="geo-options"]'
        );
        radioButtons.forEach((radioButton) => {
          radioButton.addEventListener("change", handleRadioButtonChange);
        });
      }

      function layoutGeofences(latitude, longitude, deviceId) {
        map.on("load", () => {
          fetch(`get_geometries_tracking`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              const fencecoords = [];

              // Pulls out all geofence shapes from geometries table.
              for (let i = 0; i < data.length; i++) {
                fencecoords.push(data[i].coordinates);
                // console.log(fencecoords);
              }

              console.log(fencecoords);

              const jsonString = JSON.stringify(fencecoords);
              // console.log(jsonString);

              const fencecoordsInt = JSON.parse(JSON.stringify(fencecoords)); // Create a deep copy of the fencecoords array

              // Changes fencecoords array from string to floats
              for (let k = 0; k < fencecoords.length; k++) {
                for (let i = 0; i < fencecoords[k][0].length; i++) {
                  for (let j = 0; j < fencecoords[k][0][i].length; j++) {
                    fencecoordsInt[k][0][i][j] = parseFloat(
                      fencecoords[k][0][i][j]
                    );
                  }
                }
              }

              if (
                data[0] !== "undefined" &&
                data[0] !== [] &&
                data.length > 0
              ) {
                for (let i = 0; i < fencecoordsInt.length; i++) {
                  const fence = turf.polygon(fencecoordsInt[i]);
                  const point = turf.point([
                    longitude,
                    latitude,
                  ]); /* 28.270945, -26.1471066 */
                  const isInside = turf.booleanPointInPolygon(point, fence);

                  // 0 disabled
                  // 1 entering
                  // 2 leaving

                  if (data[0].geofence_io == 1 && isInside === true) {
                    console.log("running 1");

                    map.addSource("redInGeo" + i, {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          type: "Polygon",

                          coordinates: [fencecoordsInt[i][0]],
                        },
                      },
                    });

                    map.addLayer({
                      id: "redInGeoFill" + i,
                      type: "fill",
                      source: "redInGeo" + i,
                      layout: {},
                      paint: {
                        "fill-color": "red",
                        "fill-opacity": 0.3,
                      },
                    });

                    map.addLayer({
                      id: "redInGeoOutLine" + i,
                      type: "line",
                      source: "redInGeo" + i,
                      layout: {},
                      paint: {
                        "line-color": "red",
                        "line-width": 1,
                      },
                    });
                  } else if (data[0].geofence_io == 1 && isInside == false) {
                    console.log("running 2");

                    // console.log("2");
                    // console.log("Green when outside of geofence");

                    map.addSource("greenOutGeo" + i, {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          type: "Polygon",
                          coordinates: [fencecoordsInt[i][0]],
                        },
                      },
                    });

                    map.addLayer({
                      id: "greenOutGeoFill" + i,
                      type: "fill",
                      source: "greenOutGeo" + i,
                      layout: {},
                      paint: {
                        "fill-color": "green",
                        "fill-opacity": 0.3,
                      },
                    });

                    map.addLayer({
                      id: "greenOutGeoOutline" + i,
                      type: "line",
                      source: "greenOutGeo" + i,
                      layout: {},
                      paint: {
                        "line-color": "green",
                        "line-width": 1,
                      },
                    });
                  } else if (data[0].geofence_io == 2 && isInside == true) {
                    console.log("running 3");
                    // console.log("3");
                    // console.log("Green when inside of geofence");

                    map.addSource("greenInGeo" + i, {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          type: "Polygon",
                          coordinates: [fencecoordsInt[i][0]],
                        },
                      },
                    });

                    map.addLayer({
                      id: "greenInGeoFill" + i,
                      type: "fill",
                      source: "greenInGeo" + i,
                      layout: {},
                      paint: {
                        "fill-color": "green",
                        "fill-opacity": 0.3,
                      },
                    });

                    map.addLayer({
                      id: "greenInGeoOutLine" + i,
                      type: "line",
                      source: "greenInGeo" + i,
                      layout: {},
                      paint: {
                        "line-color": "green",
                        "line-width": 1,
                      },
                    });
                  } else if (data[0].geofence_io == 2 && isInside == false) {
                    console.log("running 4");
                    // console.log("4");
                    // console.log("Red when outside of geofence");

                    map.addSource("redOutGeo" + i, {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          type: "Polygon",
                          coordinates: [fencecoordsInt[i][0]],
                        },
                      },
                    });

                    map.addLayer({
                      id: "redOutGeoFill" + i,
                      type: "fill",
                      source: "redOutGeo" + i,
                      layout: {},
                      paint: {
                        "fill-color": "red",
                        "fill-opacity": 0.3,
                      },
                    });

                    map.addLayer({
                      id: "redOutGeoOutline" + i,
                      type: "line",
                      source: "redOutGeo" + i,
                      layout: {},
                      paint: {
                        "line-color": "red",
                        "line-width": 1,
                      },
                    });
                  } else {
                    console.log("running 5");
                    map.addSource("blueOutGeo" + i, {
                      type: "geojson",
                      data: {
                        type: "Feature",
                        geometry: {
                          type: "Polygon",
                          coordinates: [fencecoordsInt[i][0]],
                        },
                      },
                    });

                    map.addLayer({
                      id: "blueOutGeoFill" + i,
                      type: "fill",
                      source: "blueOutGeo" + i,
                      layout: {},
                      paint: {
                        "fill-color": "blue",
                        "fill-opacity": 0.3,
                      },
                    });

                    map.addLayer({
                      id: "blueOutGeoOutline" + i,
                      type: "line",
                      source: "blueOutGeo" + i,
                      layout: {},
                      paint: {
                        "line-color": "blue",
                        "line-width": 1,
                      },
                    });
                  }
                }
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      }

      function latestMarker() {
        const longitude = gon.device_locations[0].longitude;
        const latitude = gon.device_locations[0].latitude;
        // Marker
        const marker = new mapboxgl.Marker()
          .setLngLat([longitude, latitude])
          .addTo(map);
        // End: Marker
      }

      function mapLayer(mapStyle, latitude, longitude) {
        // Create the map layer & style
        const mapBoxToken =
          "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

        mapboxgl.accessToken = mapBoxToken;
        map = new mapboxgl.Map({
          container: "tracking-map", // container ID
          style: mapStyle, // style URL
          center: [longitude, latitude + 0.001], // starting position [lng, lat]
          attribution: "Nvirosense",
          zoom: 16, // starting zoom
          // pitch: 70,
          cooperativeGestures: true,
          attributionControl: false,
        });

        map.on("load", () => {
          map.addSource("dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          });
          map.addLayer(
            {
              id: "hillshading",
              source: "dem",
              type: "hillshade",
            },
            // Insert below land-structure-polygon layer,
            // where hillshading sits in the Mapbox Streets style.
            "land-structure-polygon"
          );
        });

        //End: Create the map layer & style
      }

      function modelBuildings() {
        map.on("style.load", () => {
          // Insert the layer beneath any symbol layer.
          // const layers = map.getStyle().layers;
          // const labelLayerId = layers.find(
          //   (layer) => layer.type === "symbol" && layer.layout["text-field"]
          // ).id;

          // The 'building' layer in the Mapbox Streets
          // vector tileset contains building height data
          // from OpenStreetMap.
          map.addLayer(
            {
              id: "add-3d-buildings",
              source: "composite",
              "source-layer": "building",
              filter: ["==", "extrude", "true"],
              type: "fill-extrusion",
              minzoom: 15,
              paint: {
                "fill-extrusion-color": "#aaa",

                // Use an 'interpolate' expression to
                // add a smooth transition effect to
                // the buildings as the user zooms in.
                "fill-extrusion-height": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  15,
                  0,
                  15.05,
                  ["get", "height"],
                ],
                "fill-extrusion-base": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  15,
                  0,
                  15.05,
                  ["get", "min_height"],
                ],
                "fill-extrusion-opacity": 0.6,
              },
            }
            // labelLayerId
          );
        });
      }

      function latestMarkerPopup(latitude, longitude) {
        // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

        const deviceName = gon.device_locations[0].device_name;
        const deviceType = gon.device_locations[0].type;

        let fullCoords = latitude + "°<br>" + longitude + "°";

        let dateString = gon.device_locations[0].received_at;

        let date = new Date(dateString);
        let unixTime = date.getTime();

        const formatDate = gon.device_locations[0].received_at.slice(0, 10);
        const formatTime = gon.device_locations[0].received_at.slice(11, 19);

        let elapsedTime = formatLastSeen(unixTime);

        // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
        const mapboxApi =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          longitude +
          "," +
          latitude +
          ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        fetch(mapboxApi)
          .then((response) => response.json())
          .then((data) => {
            // Extract the place_name property and store it in a variable
            const placeName = data.features[0].place_name;

            // Popup
            let popup = new AnimatedPopup({
              anchor: "bottom",
              offset: 25,
              openingAnimation: {
                duration: 600,
                easing: "easeOutElastic",
                transform: "scale",
              },
              closingAnimation: {
                duration: 200,
                easing: "easeInBack",
                transform: "scale",
              },
            }).setHTML(`
          <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
            // End: Popup

            // Marker
            const marker = new mapboxgl.Marker()
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map);
            // End: Marker

            popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

            // console.log(placeName);
          })
          .catch((error) => {
            // Handle any errors that occur during the fetch request
            // console.error(error);
          });

        // Remove the navigation control from the map
        map.removeControl(navControl);

        // Add the navigation control to the map
        map.addControl(navControl);

        // End: API to GET adress
      }

      function sosMarkerPopup(latitude, longitude) {
        // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

        const deviceName = gon.device_locations[0].device_name;
        const deviceType = gon.device_locations[0].type;

        let fullCoords = latitude + "°<br>" + longitude + "°";

        let dateString = gon.device_locations[0].received_at;

        let date = new Date(dateString);
        let unixTime = date.getTime();

        const formatDate = gon.device_locations[0].received_at.slice(0, 10);
        const formatTime = gon.device_locations[0].received_at.slice(11, 19);

        let elapsedTime = formatLastSeen(unixTime);

        // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
        const mapboxApi =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          longitude +
          "," +
          latitude +
          ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        fetch(mapboxApi)
          .then((response) => response.json())
          .then((data) => {
            // Extract the place_name property and store it in a variable
            const placeName = data.features[0].place_name;

            // Popup
            let popup = new AnimatedPopup({
              anchor: "bottom",
              offset: 25,
              openingAnimation: {
                duration: 600,
                easing: "easeOutElastic",
                transform: "scale",
              },
              closingAnimation: {
                duration: 200,
                easing: "easeInBack",
                transform: "scale",
              },
            }).setHTML(`
          <h1 class='popup-head' style= "color:red"><strong>S.O.S : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
            // End: Popup

            popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

            var paragraphs = document.querySelectorAll(
              ".mapboxgl-popup-content"
            );
            paragraphs.style.border = "2px solid red";

            // console.log(placeName);
          })
          .catch((error) => {
            // Handle any errors that occur during the fetch request
            // console.error(error);
          });

        // Remove the navigation control from the map
        map.removeControl(navControl);

        // Add the navigation control to the map
        map.addControl(navControl);

        // End: API to GET adress
      }

      function manDownMarkerPopup(latitude, longitude) {
        // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

        const deviceName = gon.device_locations[0].device_name;
        const deviceType = gon.device_locations[0].type;

        let fullCoords = latitude + "°<br>" + longitude + "°";

        let dateString = gon.device_locations[0].received_at;

        let date = new Date(dateString);
        let unixTime = date.getTime();

        const formatDate = gon.device_locations[0].received_at.slice(0, 10);
        const formatTime = gon.device_locations[0].received_at.slice(11, 19);

        let elapsedTime = formatLastSeen(unixTime);

        // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
        const mapboxApi =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          longitude +
          "," +
          latitude +
          ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        fetch(mapboxApi)
          .then((response) => response.json())
          .then((data) => {
            // Extract the place_name property and store it in a variable
            const placeName = data.features[0].place_name;

            // Popup
            let popup = new AnimatedPopup({
              anchor: "bottom",
              offset: 25,
              openingAnimation: {
                duration: 600,
                easing: "easeOutElastic",
                transform: "scale",
              },
              closingAnimation: {
                duration: 200,
                easing: "easeInBack",
                transform: "scale",
              },
            }).setHTML(`
          <h1 class='popup-head' style='color:red'><strong>Man Down : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
            // End: Popup

            popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

            // console.log(placeName);
          })
          .catch((error) => {
            // Handle any errors that occur during the fetch request
            // console.error(error);
          });

        // Remove the navigation control from the map
        map.removeControl(navControl);

        // Add the navigation control to the map
        map.addControl(navControl);

        // End: API to GET adress
      }

      function flyToLocation() {
        map.flyTo({
          center: [
            gon.device_locations[0].longitude,
            gon.device_locations[0].latitude,
          ],
          zoom: 17,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }

      // function replayHistory() {
      //   const target = [];
      //   const camera = [];

      //   for (let i = 0; i < gon.device_locations.length - 1; i++) {
      //     target.push([
      //       gon.device_locations[i].longitude,
      //       gon.device_locations[i].latitude,
      //     ]);
      //     camera.push([
      //       gon.device_locations[i].longitude + 0.001,
      //       gon.device_locations[i].latitude,
      //     ]);
      //   }

      //   mapboxgl.accessToken =
      //     "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      //   const map = new mapboxgl.Map({
      //     container: "tracking-map",
      //     zoom: 15,
      //     center: [
      //       gon.device_locations[0].longitude,
      //       gon.device_locations[0].latitude,
      //     ],
      //     pitch: 90,
      //     bearing: 0,
      //     // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      //     style: mapStyle,
      //     interactive: false,
      //     attributionControl: false,
      //   });

      //   // `routes` comes from https://docs.mapbox.com/mapbox-gl-js/assets/routes.js,
      //   // which has properties that are in the shape of an array of arrays that correspond
      //   //  to the `coordinates` property of a GeoJSON linestring, for example:
      //   // [
      //   //   [6.56158, 46.05989],
      //   //   [6.56913, 46.05679],
      //   //   ...
      //   // ]
      //   // this is the path the camera will look at
      //   const targetRoute = target;
      //   // this is the path the camera will move along
      //   const cameraRoute = camera;

      //   // add terrain, sky, and line layers once the style has loaded
      //   map.on("style.load", () => {
      //     map.addSource("mapbox-dem", {
      //       type: "raster-dem",
      //       url: "mapbox://mapbox.mapbox-terrain-dem-v1",
      //       tileSize: 512,
      //       maxzoom: 18,
      //     });
      //     map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
      //     map.addSource("trace", {
      //       type: "geojson",
      //       data: {
      //         type: "Feature",
      //         properties: {},
      //         geometry: {
      //           type: "LineString",
      //           coordinates: targetRoute,
      //         },
      //       },
      //     });
      //     map.addLayer({
      //       type: "line",
      //       source: "trace",
      //       id: "line",
      //       paint: {
      //         "line-color": "black",
      //         "line-width": 2,
      //       },
      //       layout: {
      //         "line-cap": "round",
      //         "line-join": "round",
      //       },
      //     });
      //   });

      //   function modelBuildings() {
      //     map.on("style.load", () => {
      //       // Insert the layer beneath any symbol layer.
      //       const layers = map.getStyle().layers;
      //       const labelLayerId = layers.find(
      //         (layer) => layer.type === "symbol" && layer.layout["text-field"]
      //       ).id;

      //       // The 'building' layer in the Mapbox Streets
      //       // vector tileset contains building height data
      //       // from OpenStreetMap.
      //       map.addLayer(
      //         {
      //           id: "add-3d-buildings",
      //           source: "composite",
      //           "source-layer": "building",
      //           filter: ["==", "extrude", "true"],
      //           type: "fill-extrusion",
      //           minzoom: 15,
      //           paint: {
      //             "fill-extrusion-color": "#aaa",

      //             // Use an 'interpolate' expression to
      //             // add a smooth transition effect to
      //             // the buildings as the user zooms in.
      //             "fill-extrusion-height": [
      //               "interpolate",
      //               ["linear"],
      //               ["zoom"],
      //               15,
      //               0,
      //               15.05,
      //               ["get", "height"],
      //             ],
      //             "fill-extrusion-base": [
      //               "interpolate",
      //               ["linear"],
      //               ["zoom"],
      //               15,
      //               0,
      //               15.05,
      //               ["get", "min_height"],
      //             ],
      //             "fill-extrusion-opacity": 0.6,
      //           },
      //         },
      //         labelLayerId
      //       );
      //     });
      //   }
      //   modelBuildings();

      //   // wait for the terrain and sky to load before starting animation
      //   map.on("load", () => {
      //     let animationDuration = gon.device_locations.length * 15000;
      //     const cameraAltitude = 3000;
      //     // get the overall distance of each route so we can interpolate along them
      //     const routeDistance = turf.lineDistance(turf.lineString(targetRoute));
      //     const cameraRouteDistance = turf.lineDistance(
      //       turf.lineString(cameraRoute)
      //     );

      //     // map.removeControl(draw);

      //     let start;

      //     document
      //       .getElementById("speed-x1")
      //       .addEventListener("click", function () {
      //         animationDuration = gon.device_locations.length * 15000;
      //         // alert(animationDuration);
      //       });

      //     document
      //       .getElementById("speed-x2")
      //       .addEventListener("click", function () {
      //         animationDuration = gon.device_locations.length * 6000;
      //         // alert(animationDuration);
      //       });

      //     document
      //       .getElementById("speed-x4")
      //       .addEventListener("click", function () {
      //         animationDuration = gon.device_locations.length * 2000;
      //       });

      //     function frame(time) {
      //       if (!start) start = time;
      //       // phase determines how far through the animation we are
      //       const phase = (time - start) / animationDuration;

      //       // phase is normalized between 0 and 1
      //       // when the animation is finished, reset start to loop the animation
      //       if (phase > 1) {
      //         // wait 1.5 seconds before looping
      //         setTimeout(() => {
      //           start = 0.0;
      //         }, 1500);
      //       }

      //       // use the phase to get a point that is the appropriate distance along the route
      //       // this approach syncs the camera and route positions ensuring they move
      //       // at roughly equal rates even if they don't contain the same number of points
      //       const alongRoute = turf.along(
      //         turf.lineString(targetRoute),
      //         routeDistance * phase
      //       ).geometry.coordinates;

      //       const alongCamera = turf.along(
      //         turf.lineString(cameraRoute),
      //         cameraRouteDistance * phase
      //       ).geometry.coordinates;

      //       const camera = map.getFreeCameraOptions();

      //       // set the position and altitude of the camera
      //       camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
      //         {
      //           lng: alongCamera[0],
      //           lat: alongCamera[1],
      //         },
      //         cameraAltitude
      //       );

      //       // tell the camera to look at a point along the route
      //       camera.lookAtPoint({
      //         lng: alongRoute[0],
      //         lat: alongRoute[1],
      //       });

      //       map.setFreeCameraOptions(camera);

      //       window.requestAnimationFrame(frame);
      //     }

      //     window.requestAnimationFrame(frame);
      //   });
      // }

      function removeGeofences() {

      }


      function pulsingDotInit(latitude, longitude) {
        // Pulsing Dot implement (Adding layer will be in > for loop> if statement)
        const size = 100;

        // This implements `StyleImageInterface`
        // to draw a pulsing dot icon on the map.
        const pulsingDot = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),

          // When the layer is added to the map,
          // get the rendering context for the map canvas.
          onAdd: function () {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext("2d");
          },

          // Call once before every frame where the icon will be used.
          render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;

            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;

            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
            );
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
            context.fill();

            // Draw the inner circle.
            context.beginPath();
            context.arc(
              this.width / 2,
              this.height / 2,
              radius,
              0,
              Math.PI * 2
            );
            context.fillStyle = "rgba(255, 100, 100, 1)";
            context.strokeStyle = "white";
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // Update this image's data with data from the canvas.
            this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height
            ).data;

            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            map.triggerRepaint();

            // Return `true` to let the map know that the image was updated.
            return true;
          },
        };
        // End: Pulsing Dot implement (Adding layer will be in > for loop> if statement)
        // Red pulse marker
        map.on("load", () => {
          map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 1 });

          map.addSource("dot-point", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [longitude, latitude], // icon position [lng, lat]
                  },
                },
              ],
            },
          });
          map.addLayer({
            id: "layer-with-pulsing-dot",
            type: "symbol",
            source: "dot-point",
            layout: {
              "icon-image": "pulsing-dot",
            },
          });
        });
      }

      function reset(mapStyle, latitude, longitude) {
        mapLayer(mapStyle, latitude, longitude);
        modelBuildings();
        latestMarkerPopup(latitude, longitude);
      }

      function geofence(mapStyle, latitude, longitude, geofence_io, deviceId) {
        var currentPolygon = null;

        mapboxgl.accessToken =
          "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        const map = new mapboxgl.Map({
          container: "tracking-map", // container ID
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: mapStyle, // style URL
          center: [latitude, longitude + 0.001], // starting position [lng, lat]
          zoom: 14, // starting zoom
          attributionControl: false,
        });

        map.on("load", function () {
          var draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
              polygon: true,
              trash: true,
            },
            styles: [
              {
                id: "gl-draw-polygon-fill",
                type: "fill",
                paint: {
                  "fill-color": "#20C997", // change fill color here
                  "fill-outline-color": "black", // change outline color here
                  "fill-opacity": 0.3,
                },
              },
              {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"],
                ],
                paint: {
                  "circle-radius": 8,
                  "circle-color": "black",
                  "circle-stroke-width": 2, // Border width in pixels
                  "circle-stroke-color": "#fff", // Border color
                },
              },
              {
                id: "gl-draw-polygon-and-line-vertex-inactive",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["==", "mode", "static"],
                ],
                paint: {
                  "circle-radius": 5,
                  "circle-color": "#20C997",
                },
              },
              // Line layer for the polygon outline
              {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                filter: [
                  "all",
                  ["==", "$type", "Polygon"],
                  ["!=", "mode", "static"],
                ],
                layout: {
                  "line-cap": "round",
                  "line-join": "round",
                },
                paint: {
                  "line-color": "black", // Change line color here
                  "line-width": 2, // Change line width here
                },
              },

              {
                id: "gl-draw-line-midpoint-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "midpoint"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"],
                ],
                paint: {
                  "circle-radius": 5,
                  "circle-color": "#2aff46",
                  "circle-stroke-width": 2, // Border width in pixels
                  "circle-stroke-color": "#fff", // Border color
                },
              },
            ],
          });

          const point = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [latitude, longitude],
            },
          };

          map.addSource("point", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [point],
            },
          });

          map.addLayer({
            id: "current_geo",
            type: "circle",
            source: "point",
            paint: {
              "circle-color": "#007bff",
              "circle-radius": 8,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#ffffff",
            },
          });

          map.addControl(draw);

          fetch(`get_geometries_tracking`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data[0] === null || data[0] === undefined || data[0] === "") {
                const noGeofenceDrawn = document.getElementById("no-geofence-drawn");
                noGeofenceDrawn.style.visibility = "visible";

              } else {
                // console.log("Made");
                const sidebar = document.getElementById("leftMenu");
                const infoHeadings = document.getElementById("info-headings");
                const geofenceRadioButtons = document.getElementById("geofence-radio-buttons");



                setTimeout(function () {
                  geofenceRadioButtons.style.visibility = "visible";

                  sidebar.style.width = "400px";
                }, 220);
              }

              const fencecoords = [];

              for (let i = 0; i < data.length; i++) {
                fencecoords.push(data[i].coordinates);
                // console.log(fencecoords);
              }

              // const jsonString = JSON.stringify(fencecoords);
              // console.log(jsonString);

              const fencecoordsInt = JSON.parse(JSON.stringify(fencecoords)); // Create a deep copy of the fencecoords array

              for (let k = 0; k < fencecoords.length; k++) {
                for (let i = 0; i < fencecoords[k][0].length; i++) {
                  for (let j = 0; j < fencecoords[k][0][i].length; j++) {
                    fencecoordsInt[k][0][i][j] = parseFloat(
                      fencecoords[k][0][i][j]
                    );
                  }
                }
              }

              const preSavedPolygon = {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [[]],
                },
                properties: {},
              };

              for (let i = 0; i < data.length; i++) {
                preSavedPolygon.geometry.coordinates[0] = [];
                preSavedPolygon.properties.id = "";

                for (let j = 0; j < data[i].coordinates[0].length; j++) {
                  const longitude = parseFloat(data[i].coordinates[0][j][0]);
                  const latitude = parseFloat(data[i].coordinates[0][j][1]);

                  preSavedPolygon.geometry.coordinates[0].push([
                    longitude,
                    latitude,
                  ]);
                }

                preSavedPolygon.id = data[i].shape_id;

                // console.log(preSavedPolygon);
                draw.add(preSavedPolygon);
              }
            })

            // map.on("load", function () {})
            .catch((error) => {
              console.error("Error:", error);
            });
        });

        // latestBtn.addEventListener("click", function () {
        //   reset();
        //   map.removeControl(draw);
        // });

        // Remove the navigation control from the map
        map.removeControl(navControl);
        // Add the navigation control to the map
        map.addControl(navControl);

        map.on("draw.create", function (e) {
          const sidebar = document.getElementById("leftMenu");
          const infoHeadings = document.getElementById("info-headings");
          const geofenceRadioButtons = document.getElementById("geofence-radio-buttons");
          const noGeofenceDrawn = document.getElementById("no-geofence-drawn");



          // infoHeadings.style.visibility = "hidden";
          geofenceRadioButtons.style.visibility = "visible";
          noGeofenceDrawn.style.visibility = "hidden";


          sidebar.style.width = "400px";

          const data = {
            geofences: [],
          };

          const coords = e.features[0].geometry.coordinates;

          const shape_id = e.features[0].id;

          data.geofences.push({
            shape_id: shape_id,
            coordinates: coords,
          });

          // console.log(
          //   JSON.stringify({ shape_id: shape_id, coordinates: coords })
          // );

          const fence = turf.polygon(coords);
          const point = turf.point([
            latitude,
            longitude,
          ]); /* 28.270945, -26.1471066 */
          const isInside = turf.booleanPointInPolygon(point, fence);
          // console.log(longitude, latitude);
          // console.log(e.features[0].geometry.coordinates);
          // console.log(isInside);

          try {
            if (document.querySelector("#disable-geofence").checked) {
              geofence_io = 0;
            } else if (document.querySelector("#entering-geofence").checked) {
              geofence_io = 1;
            } else if (document.querySelector("#leaving-geofence").checked) {
              geofence_io = 2;
            }
          } catch (error) {
            console.log("No geofences");
          }

          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          fetch(`/devices/${deviceId}/create_geometries_tracking`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({
              shape_id: shape_id,
              coordinates: coords,
              geofence_io: geofence_io,
            }),
          })
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => console.error(error));
        });

        map.on("draw.delete", function (e) {
          // Get the ID of the deleted polygon
          let shape_id = e.features[0].id;

          // alert(e.features[0].id + " Delete?");

          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          fetch(`remove_geometries_tracking`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ shape_id: shape_id }),
          })
            // .then(response => {
            //   console.log(shape_id)
            // })
            .catch((error) => console.error(error));

          fetch(`get_geometries_tracking`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log(data.length);
              // console.log(data[0]);
              if (data[1] === null || data[1] === undefined || data[1] === "") {
                // Shape is empty

                const sidebar = document.getElementById("leftMenu");
                const infoHeadings = document.getElementById("info-headings");
                const geofenceRadioButtons = document.getElementById("geofence-radio-buttons");
                const noGeofenceDrawn = document.getElementById("no-geofence-drawn");



                geofenceRadioButtons.style.visibility = "hidden";
                noGeofenceDrawn.style.visibility = "visible";


                setTimeout(function () {
                  infoHeadings.style.visibility = "visible";
                  sidebar.style.width = "400px";
                }, 220);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });

        map.on("draw.update", function (e) {
          let data = {
            geofences: [],
          };

          // alert("Update Called");

          // Get the coordinates of the newly created polygon
          let coords = e.features[0].geometry.coordinates;
          // coords[coords.length - 1].pop();

          let shape_id = e.features[0].id;

          data.geofences.push({ shape_id: shape_id, coordinates: coords }); // Add the coordinates to the polygons array

          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          fetch(`update_geometries_tracking`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ shape_id: shape_id, coordinates: coords }),
          })
            // .then(response => {
            //   // do something with response, such as redirect user to thank you page
            // })
            .catch((error) => console.error(error));
        });

        // const jsonString2 = JSON.stringify(fencecoordsInt[0]);
        // console.log(jsonString2);

        // const fence = turf.polygon(fencecoordsInt[0]);

        // const point = turf.point([longitude, latitude]);/* 28.270945, -26.1471066 */

        // const isInside = turf.booleanPointInPolygon(point, fence);

        // console.log(isInside)
      }

      function set_geofence_io() {
        fetch(`get_geometries_tracking`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            try {
              if (data[0].geofence_io == 0) {
                document.querySelector("#disable-geofence").checked = true;
              } else if (data[0].geofence_io == 1) {
                document.querySelector("#entering-geofence").checked = true;
              } else if (data[0].geofence_io == 2) {
                document.querySelector("#leaving-geofence").checked = true;
              }
            } catch (error) {
              console.log("No geofences");
            }
          });
      }

      function getAddresses() {
        const addresses = [];

        for (let i = 0; i < gon.device_locations.length; i++) {
          const longitude = gon.device_locations[i].longitude;
          const latitude = gon.device_locations[i].latitude;
          addresses.push([latitude, longitude]);
        }

        // console.log(addresses);

        for (let i = 0; i < addresses.length; i++) {
          const [latitude, longitude] = addresses[i];

          const mapboxApi = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw`;

          fetch(mapboxApi)
            .then((response) => response.json())
            .then((data) => {
              // Extract the formatted address property and store it in a variable
              const formattedAddress = data.features[0].properties.address;

              // console.log(formattedAddress);
            })
            .catch((error) => {
              // console.error(error);
            });
        }
      }

      function overview(mapStyle, latitude, longitude) {
        // getAddresses();

        document.getElementsByClassName(
          "mapboxgl-ctrl-group"
        )[0].style.display = "none";

        let locations = []; // overview

        for (let i = 0; i < gon.device_locations.length - 1; i++) {
          locations.push([
            gon.device_locations[i].longitude,
            gon.device_locations[i].latitude,
          ]);
        }

        mapboxgl.accessToken =
          "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        // A GeoJSON object with a LineString route from the White House to Capitol Hill
        const geojson = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                properties: {},
                coordinates: locations,
              },
            },
          ],
        };

        const map = new mapboxgl.Map({
          container: "tracking-map",
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: mapStyle,
          center: [longitude, latitude],
          zoom: 12,
          attributionControl: false,
        });

        map.on("load", () => {
          map.addSource("LineString", {
            type: "geojson",
            data: geojson,
          });
          map.addLayer({
            id: "LineString",
            type: "line",
            source: "LineString",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#000000",
              "line-width": 2,
            },
          });

          // Geographic coordinates of the LineString
          const coordinates = geojson.features[0].geometry.coordinates;

          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of locations) {
            bounds.extend(coord);
          }

          map.fitBounds(bounds, {
            padding: 20,
          });

          // Loop through the locations and add a separate point layer for each coordinate
          for (let i = 0; i < locations.length; i++) {
            const formatDate = gon.device_locations[i].received_at.slice(0, 10);
            const formatTime = gon.device_locations[i].received_at.slice(
              11,
              19
            );
            const dateTime = formatDate + " " + formatTime;

            const point = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: locations[i],
              },
              properties: {
                latitude: gon.device_locations[i].latitude,
                longitude: gon.device_locations[i].longitude,
                device_name: gon.device_locations[i].device_name,
                received_at: dateTime,
                dev_status: gon.device_locations[i].dev_status,
              },
            };
            map.addSource("point" + i, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [point],
              },
            });

            if (
              gon.device_locations[i].dev_status == "sos" ||
              gon.device_locations[i].dev_status == "mandown"
            ) {
              map.addLayer({
                id: "point" + i,
                type: "circle",
                source: "point" + i,
                paint: {
                  "circle-color": "#ff6464 ",
                  "circle-radius": 8,
                  "circle-stroke-width": 2,
                  "circle-stroke-color": "#ffffff",
                },
              });
            } else {
              map.addLayer({
                id: "point" + i,
                type: "circle",
                source: "point" + i,
                paint: {
                  "circle-color": "#007bff",
                  "circle-radius": 8,
                  "circle-stroke-width": 2,
                  "circle-stroke-color": "#ffffff",
                },
              });
            }

            // Add a click event listener to center the map on the clicked point

            map.on("click", "point" + i, (e) => {
              const info = e.features[0].properties;

              const [latitude, longitude] = e.features[0].geometry.coordinates;
              const roundedLongitude = longitude.toFixed(9);
              const roundedLatitude = latitude.toFixed(9);

              const pointCoords = e.features[0].geometry.coordinates;

              // console.log("Target " + latitude + " " + longitude);
              const recieved_uplink = e.features[0].properties.received_at;
              const deviceName = e.features[0].properties.device_name;
              const deviceStatus = e.features[0].properties.dev_status;

              let date = new Date(recieved_uplink);
              let unixTime = date.getTime();

              const formatDate = gon.device_locations[0].received_at.slice(
                0,
                10
              );
              const formatTime = gon.device_locations[0].received_at.slice(
                11,
                19
              );

              let elapsedTime = formatLastSeen(unixTime);

              const dateTime = formatDate + " " + formatTime;

              if (deviceStatus == "sos") {
                var popupContent = `
            <h1 class='popup-head' style="color:red"><strong>S.O.S : ${deviceName}</strong></h1>
            <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
            <colgroup>
            <col style="width: 90px">
            <col style="width: 80px">
            </colgroup>
            <thead>
            </thead>
            <tbody>
              <tr>
                <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
                <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
              </tr>
              <tr>
                <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
                <td class="popup-nvirosense-73oq">${elapsedTime}</td>
              </tr>
              <tr>
                <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
                <td class="popup-nvirosense-73oq">${roundedLatitude}</br>${roundedLongitude}</td>
              </tr>
            </tbody>
            </table>
            `;
              } else if (deviceStatus == "mandown") {
                var popupContent = `
          <h1 class='popup-head' style= "color:red"><strong>Man Down : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
          <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><strong>Time:</span></strong></td>
          <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
        </tr>
        <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><strong>Last seen:</strong></span></td>
          <td class="popup-nvirosense-73oq">${elapsedTime}</td>
        </tr>
        <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><strong>Position:</strong></span></td>
          <td class="popup-nvirosense-73oq">${roundedLatitude}</br>${roundedLongitude}</td>
        </tr>
          </tbody>
          </table>
          `;
              } else {
                var popupContent = `
          <h1 class='popup-head' <strong><b>Idle : ${deviceName}</b></strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
          <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
          <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
        </tr>
        <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
          <td class="popup-nvirosense-73oq">${elapsedTime}</td>
        </tr>
        <tr>
          <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
          <td class="popup-nvirosense-73oq">${roundedLatitude}</br>${roundedLongitude}</td>
        </tr>
          </tbody>
          </table>
          `;
              }

              // Create a popup
              const popup = new mapboxgl.Popup({ anchor: "bottom" })
                .setLngLat(pointCoords)
                .setHTML(popupContent)
                .addTo(map);

              map.flyTo({
                center: pointCoords,
                speed: 1, // Lower value for slower animation speed
                duration: 3000, // Higher value for longer animation duration (in milliseconds)
              });
            });

            // Add a mouseenter event listener to change the cursor to a pointer
            map.on("mouseenter", "point" + i, () => {
              map.getCanvas().style.cursor = "pointer";
            });

            // Add a mouseleave event listener to change the cursor back to default
            map.on("mouseleave", "point" + i, () => {
              map.getCanvas().style.cursor = "";
            });
            // console.log(point.properties.device_name);
          }
        });
      }

      function main(deviceId, deviceCurrentStatus, mapStyle, latitude, longitude){
        set_geofence_io(deviceId);
        if (deviceCurrentStatus === "sos") {
          // console.log("SOS");
          mapLayer(mapStyle, latitude, longitude);
          layoutGeofences(latitude, longitude, deviceId);
          modelBuildings();
          pulsingDotInit(latitude, longitude);
          sosMarkerPopup(latitude, longitude);
        } else if (deviceCurrentStatus === "motionless") {
          // console.log("MANDOWN");
          mapLayer(mapStyle, latitude, longitude);
          layoutGeofences(latitude, longitude, deviceId);
          modelBuildings();
          pulsingDotInit(latitude, longitude);
          manDownMarkerPopup(latitude, longitude);
        } else {
          mapLayer(mapStyle, latitude, longitude);
          layoutGeofences(latitude, longitude, deviceId);
          modelBuildings();
          latestMarkerPopup(latitude, longitude);
        }
      }

      // Code logic:

      if (
        typeof gon.device_locations === "undefined" ||
        typeof gon.device_locations[0] === "undefined" ||
        gon.device_locations[0].latitude === "" ||
        gon.device_locations[0].latitude === 0
      ) {
        document.getElementById("tracking-map").remove();
      } else {
        // Variables
        var map; // so that maplayers can be added within the functions
        let draw;
        const mapStyle = "mapbox://styles/mapbox/outdoors-v12";
        const deviceCurrentStatus = gon.device_locations[0].dev_status;
        var navControl = new mapboxgl.NavigationControl();
        var fsControl = new mapboxgl.FullscreenControl();
        const longitude = gon.device_locations[0].longitude;
        const latitude = gon.device_locations[0].latitude;
        let geofence_io = 0;
        let idPathArray = window.location.pathname.split("/");
        let deviceId = idPathArray[2];

        const sidebar = document.getElementById("leftMenu");
        const closeBtn = document.getElementById("closebtn");
        const expandBtn = document.getElementById("expandbtn");
        // const replayBtn = document.getElementById("replay-history");
        const devStatusBtn = document.getElementById("device-status");
        const overviewBtn = document.getElementById("history-overview");
        const geofenceBtn = document.getElementById("geofences");
        const latestBtn = document.getElementById("last-uplink");
        const infoHeadings = document.getElementById("info-headings");
        const geofenceRadioButtons = document.getElementById("geofence-radio-buttons");
        const noGeofenceDrawn = document.getElementById("no-geofence-drawn");
        const radioLeaveBtn = document.getElementById("leaving-geofence");
        const radioEnterBtn = document.getElementById("entering-geofence");
        const radioDisableBtn = document.getElementById("disable-geofence");
        const buttons = document.querySelectorAll(".nav-link");
        const removeAllGeoBtn = document.getElementById("remove-all-geofences");


        main(deviceId, deviceCurrentStatus, mapStyle, latitude, longitude);

        // replayBtn.addEventListener("click", function () {
        //   replayHistory();
        //   sidebar.style.width = "0px";
        //   sidebar.style.padding = "0";
        //   expandBtn.style.visibility = "visible";
        // });

        overviewBtn.addEventListener("click", function () {
          overview(mapStyle, latitude, longitude);
          sidebar.style.width = "0px";
          sidebar.style.padding = "0";
          expandBtn.style.visibility = "visible";
          infoHeadings.style.visibility = "visible";
          geofenceRadioButtons.style.visibility = "hidden";
          noGeofenceDrawn.style.visibility = "hidden";


        });

        geofenceBtn.addEventListener("click", function () {
          geofence(
            mapStyle,
            longitude,
            latitude,
            geofence_io,
            deviceId
          );
          sidebar.style.width = "-20px";
        });

        // latestBtn.addEventListener("click", function () {
        //   reset(mapStyle, latitude, longitude);
        //   sidebar.style.width = "0px";
        //   sidebar.style.padding = "0";
        //   expandBtn.style.visibility = "visible";
        //   geofenceRadioButtons.style.visibility = "hidden";


        devStatusBtn.addEventListener("click", function () {
          main(deviceId, deviceCurrentStatus, mapStyle, latitude, longitude)
          sidebar.style.width = "0px";
          sidebar.style.padding = "0";
          expandBtn.style.visibility = "visible";
          geofenceRadioButtons.style.visibility = "hidden";
          noGeofenceDrawn.style.visibility = "hidden";
        });

        closeBtn.addEventListener("click", function () {
          sidebar.style.width = "0px";
          sidebar.style.padding = "0";
          expandBtn.style.visibility = "visible";
        });

        expandBtn.addEventListener("click", function () {
          sidebar.style.width = "400px";
          sidebar.style.padding = "1rem";
          expandBtn.style.visibility = "hidden";
        });

        expandBtn.addEventListener("click", expandSidebarMobile);

        radioLeaveBtn.addEventListener("click", function () {
          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          fetch(`set_geometries_io_tracking`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ in_out: 0, geofence_io: 2 }),
          })
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => console.error(error));

          // for (let i = 0; i < data.length; i++) {
          //   const fence = turf.polygon(data[i]);
          //   const point = turf.point([longitude, latitude]);
          //   const isInside = turf.booleanPointInPolygon(point, fence);
          // }
        });

        radioEnterBtn.addEventListener("click", function () {
          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;
          fetch(`set_geometries_io_tracking`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ geofence_io: 1 }),
          })
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => console.error(error));
        });

        radioDisableBtn.addEventListener("click", function () {
          const csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
          ).content;

          fetch(`set_geometries_io_tracking`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ geofence_io: 0 }),
          })
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => console.error(error));
        });

        removeAllGeoBtn.addEventListener("click", function () {
          geofence(
            mapStyle,
            longitude,
            latitude,
            geofence_io,
            deviceId
          );
          geofenceRadioButtons.style.visibility = "hidden";
          noGeofenceDrawn.style.visibility = "visible";
        });


        function expandSidebarMobile() {
          if (window.innerWidth <= 400) {
            sidebar.style.width = "95%";
          } else {
            sidebar.style.width = "400px";
          }
        }


      }

      // const speed2xBtn = document.getElementById("speed-x2");
      // const speed4xBtn = document.getElementById("speed-x4");
      // speed2xBtn.addEventListener("click", function(){
      //   animationDuration = 2500;
      // });
      // speed4xBtn.addEventListener("click", function(){
      //   animationDuration = 1250;
      // });

      // document
      //   .getElementById("show-overlay")
      //   .addEventListener("click", showOverlay);
    } catch (error) {
      console.log(error);
    }
  }
}
