$(document).on("turbolinks:load", function () {
  try {
    if (document.getElementById("set-mode-nss500") == null) {
    } else {
      const doc = document.getElementById("set-mode-nss500");
      const val = doc.options[doc.selectedIndex].value;
      if (val == "0") {
        $("#mode-1-fields").show();
        $("#mode-2-fields").hide();
        $("#mode-3-fields").hide();
        $("#mode-4-fields").hide();
        $("#mode-5-fields").hide();
      } else if (val == "1") {
        $("#mode-1-fields").hide();
        $("#mode-2-fields").show();
        $("#mode-3-fields").hide();
        $("#mode-4-fields").hide();
        $("#mode-5-fields").hide();
      } else if (val == "2") {
        $("#mode-1-fields").hide();
        $("#mode-2-fields").hide();
        $("#mode-3-fields").show();
        $("#mode-4-fields").hide();
        $("#mode-5-fields").hide();
      } else if (val == "3") {
        $("#mode-1-fields").hide();
        $("#mode-2-fields").hide();
        $("#mode-3-fields").hide();
        $("#mode-4-fields").show();
        $("#mode-5-fields").hide();
      } else if (val == "4") {
        $("#mode-1-fields").hide();
        $("#mode-2-fields").hide();
        $("#mode-3-fields").hide();
        $("#mode-4-fields").hide();
        $("#mode-5-fields").show();
      }
    }
  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});
