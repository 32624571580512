import { formatLastSeen } from "../../functions/common.js";

$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")

  try {
    function checkStatus(
      deviceCurrentStatus,
      latitude,
      longitude,
      mapStyle,
      deviceName,
      deviceType,
      dateString,
      map
    ) {
      if (deviceCurrentStatus === "sos") {
        // console.log("SOS");
        mapLayer(mapStyle, latitude, longitude, map);
        modelBuildings();
        pulsingDotInit(latitude, longitude, map);
        sosMarkerPopup(latitude, longitude, deviceName, dateString, map);
      } else if (deviceCurrentStatus === "mandown") {
        // console.log("MANDOWN");
        mapLayer(mapStyle, latitude, longitude, map);
        modelBuildings();
        pulsingDotInit(latitude, longitude, map);
        manDownMarkerPopup(latitude, longitude, deviceName, dateString, map);
      } else {
        mapLayer(mapStyle, latitude, longitude, map);
        modelBuildings();
        latestMarkerPopup(
          latitude,
          longitude,
          deviceName,
          deviceType,
          dateString,
          map
        );
      }
    }

    function sosMarkerPopup(latitude, longitude, deviceName, dateString) {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.summary_location.received_at.slice(0, 10);
      const formatTime = gon.summary_location.received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            anchor: "bottom",
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
          <h1 class='popup-head' style= "color:red"><strong>SOS : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
          // End: Popup

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          var paragraphs = document.querySelectorAll(".mapboxgl-popup-content");
          paragraphs.style.border = "2px solid red";

          // console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });

      // End: API to GET adress
    }

    function manDownMarkerPopup(latitude, longitude, deviceName, dateString) {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.summary_location.received_at.slice(0, 10);
      const formatTime = gon.summary_location.received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            anchor: "bottom",
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
          <h1 class='popup-head' style='color:red'><strong>Man Down : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
          // End: Popup

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          // console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });

      // Remove the navigation control from the map
      map.removeControl(navControl);

      // Add the navigation control to the map
      map.addControl(navControl);

      // End: API to GET adress
    }

    function mapLayer(mapStyle, latitude, longitude) {
      // Create the map layer & style
      const mapBoxToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

      mapboxgl.accessToken = mapBoxToken;
      map = new mapboxgl.Map({
        container: "tracking-summary-map", // container ID
        style: mapStyle, // style URL
        center: [longitude, latitude + 0.0015], // starting position [lng, lat]
        attribution: "Nvirosense",
        zoom: 16, // starting zoom
        // pitch: 70,
        cooperativeGestures: true,
        attributionControl: false,
      });

      map.on("load", () => {
        map.addSource("dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        });
        map.addLayer(
          {
            id: "hillshading",
            source: "dem",
            type: "hillshade",
          },
          // Insert below land-structure-polygon layer,
          // where hillshading sits in the Mapbox Streets style.
          "land-structure-polygon"
        );
      });

      // Add the navigation control to the map
      map.addControl(navControl);

      //End: Create the map layer & style
    }

    function coordMarkerPopup(latitude, longitude, summaryRecievedAt) {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let date = new Date(summaryRecievedAt);
      let unixTime = date.getTime();

      const deviceName = gon.summary_location.device_name;
      const formatDate = summaryRecievedAt.slice(0, 10);
      const formatTime = summaryRecievedAt.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            anchor: "bottom",
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
      <h1 class='popup-head'><strong>${deviceName}</strong></h1>
      <table class="summary-popup-nvirosense-nss911" style="table-layout: fixed; width: 199px">
      <colgroup>
      <col style="width: 90px">
      <col style="width: 80px">
      </colgroup>
      <thead>
        <tr>
          <th class="summary-popup-nvirosense-nss911-0a7q"><span style="font-weight:400;font-style:normal">Address:</span></th>
          <th class="summary-popup-nvirosense-nss911-73oq">${placeName}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${formatDate}<br>${formatTime}</td>
        </tr>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${elapsedTime}</td>
        </tr>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${fullCoords}</td>
        </tr>
      </tbody>
      </table>
      `);
          // End: Popup

          // Marker
          const marker = new mapboxgl.Marker()
            .setLngLat([
              gon.summary_location.longitude,
              gon.summary_location.latitude,
            ])
            .setPopup(popup)
            .addTo(map);
          // End: Marker

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          // console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });
      // End: API to GET adress
    }

    function modelBuildings() {
      map.on("style.load", () => {
        // Insert the layer beneath any symbol layer.
        // const layers = map.getStyle().layers;
        // const labelLayerId = layers.find(
        //   (layer) => layer.type === "symbol" && layer.layout["text-field"]
        // ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          }
          // labelLayerId
        );
      });
    }

    function pulsingDotInit(latitude, longitude) {
      // Pulsing Dot implement (Adding layer will be in > for loop> if statement)
      const size = 100;

      // This implements `StyleImageInterface`
      // to draw a pulsing dot icon on the map.
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = "rgba(255, 100, 100, 1)";
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();

          // Return `true` to let the map know that the image was updated.
          return true;
        },
      };
      // End: Pulsing Dot implement (Adding layer will be in > for loop> if statement)
      // Red pulse marker
      map.on("load", () => {
        map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 1 });

        map.addSource("dot-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [longitude, latitude], // icon position [lng, lat]
                },
              },
            ],
          },
        });
        map.addLayer({
          id: "layer-with-pulsing-dot",
          type: "symbol",
          source: "dot-point",
          layout: {
            "icon-image": "pulsing-dot",
          },
        });
      });
    }

    function latestMarkerPopup(
      latitude,
      longitude,
      deviceName,
      deviceType,
      dateString
    ) {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.summary_location.received_at.slice(0, 10);
      const formatTime = gon.summary_location.received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            anchor: "bottom",
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
          <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
          // End: Popup

          // Marker
          const marker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(map);
          // End: Marker

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          // console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });

      // Remove the navigation control from the map
      map.removeControl(navControl);

      // Add the navigation control to the map
      map.addControl(navControl);

      // End: API to GET adress
    }

    if (
      typeof gon.summary_location === "undefined" ||
      gon.summary_location === ""
    ) {
      const summaryMap = document.getElementById("summary-map-nss911");
      if (summaryMap) {
        summaryMap.remove();
      }
    } else {
      var map; // Map is reusable, change gon
      var navControl = new mapboxgl.NavigationControl();
      const mapStyle = "mapbox://styles/mapbox/outdoors-v12";
      const latitude = gon.summary_location.latitude;
      const longitude = gon.summary_location.longitude;
      const summaryRecievedAt = gon.summary_location.received_at;
      const summaryDeviceName = gon.summary_location.device_name;
      const deviceName = gon.summary_location.device_name;
      const deviceType = gon.summary_location.device_type;
      let dateString = gon.summary_location.received_at;
      const deviceCurrentStatus = gon.summary_location.dev_status;

      checkStatus(
        deviceCurrentStatus,
        latitude,
        longitude,
        mapStyle,
        deviceName,
        deviceType,
        dateString,
        map
      );
    }
  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});
