import { Controller } from "stimulus"

// Connects to data-controller="rules"
export default class extends Controller {
  connect() {
    this.bindEvents();
    this.populateInitialSensorTypes();
  }

  bindEvents() {
    $('#rule_condition_device_id, #rule_action_device_id').on('change', (event) => {
      const selectedDeviceId = $(event.currentTarget).val();

      let url = "";
      let selectElement = null;

      if ($(event.currentTarget).attr('id') === 'rule_condition_device_id') {
        url = "/rules/get_condition_sensors";
        selectElement = $("#rule_condition_sensor_type");
      } else {
        url = "/rules/get_actionable_sensors";
        selectElement = $("#rule_action_sensor");
      }

      this.populateSensorTypes(selectElement, selectedDeviceId, url);
    });

  }

  populateInitialSensorTypes() {
    const conditionDeviceId = $('#rule_condition_device_id').val();
    if (conditionDeviceId) {
      this.populateSensorTypes($("#rule_condition_sensor_type"), conditionDeviceId, "/rules/get_condition_sensors");
    }

    const actionDeviceId = $('#rule_action_device_id').val();
    if (actionDeviceId) {
      this.populateSensorTypes($("#rule_action_sensor"), actionDeviceId, "/rules/get_actionable_sensors");
    }
  }

  populateSensorTypes(selectElement, deviceId, url) {
    $.ajax({
      url: url,
      data: { device_id: deviceId },
      success: function(result) {
        selectElement.empty();
        selectElement.append($('<option>').val('').text('Select a sensor'));
        $.each(result, function(index, value) {
          const sensorName = value.replace(/"/g, '');
          selectElement.append($('<option>').val(sensorName).text(sensorName));
        });
      },
      error: function(xhr, status, error) {
        console.error("Error fetching sensor types:", error);
      }
    });
  }
}
