import { Controller } from "stimulus";
import { formatLastSeen } from "../custom/functions/common.js";

// Connects to data-controller="groups-marker-map"
export default class extends Controller {
  connect() {
    try {
      function mapLayer(mapStyle, latitude, longitude, zoom) {
        // Create the map layer & style
        const mapBoxToken =
          "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

        mapboxgl.accessToken = mapBoxToken;
        map = new mapboxgl.Map({
          container: "deviceGroupsMap", // container ID
          style: mapStyle, // style URL
          center: [longitude, latitude], // starting position [lng, lat]
          attribution: "Nvirosense",
          zoom: zoom, // starting zoom
          // pitch: 70,
          cooperativeGestures: true,
          attributionControl: false,
        });

        map.on("load", () => {
          map.addSource("dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          });
          map.addLayer(
            {
              id: "hillshading",
              source: "dem",
              type: "hillshade",
            },
            // Insert below land-structure-polygon layer,
            // where hillshading sits in the Mapbox Streets style.
            "land-structure-polygon"
          );
        });

        //End: Create the map layer & style
      }

      function markerPopup(
        latitude,
        longitude,
        deviceName,
        deviceType,
        dateString
      ) {

        if (dateString == 'No Readings'){

          let fullCoords = latitude.toFixed(8) + "°<br>" + longitude.toFixed(8) + "°";

          // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
          const mapboxApi =
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            longitude +
            "," +
            latitude +
            ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
          fetch(mapboxApi)
            .then((response) => response.json())
            .then((data) => {
              // Extract the place_name property and store it in a variable
              const placeName = data.features[0].place_name;

              // Popup
              let popup = new AnimatedPopup({
                anchor: "bottom",
                offset: 25,
                openingAnimation: {
                  duration: 600,
                  easing: "easeOutElastic",
                  transform: "scale",
                },
                closingAnimation: {
                  duration: 200,
                  easing: "easeInBack",
                  transform: "scale",
                },
              }).setHTML(`
              <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
              <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
              <colgroup>
              <col style="width: 90px">
              <col style="width: 80px">
              </colgroup>
              <thead>
                <tr>
                  <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal"><b>Address:</b></span></th>
                  <th class="popup-nvirosense-73oq">${placeName}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Time:</b></span></td>
                  <td class="popup-nvirosense-73oq">No Readings</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Last seen:</b></span></td>
                  <td class="popup-nvirosense-73oq">No Readings</td>
                </tr>
                <tr>
                  <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal"><b>Position:</b></span></td>
                  <td class="popup-nvirosense-73oq">${fullCoords}</td>
                </tr>
              </tbody>
              </table>
              `);
              // End: Popup

             // Marker
            const marker = new mapboxgl.Marker()
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map);
            // End: Marker

            marker.getElement().addEventListener("click", function () {
              const lngLat = marker.getLngLat();

              map.flyTo({ center: [lngLat.lng, lngLat.lat + 0.011], zoom: 13 });
            });
            })
            .catch((error) => {
              // Handle any errors that occur during the fetch request
              // console.error(error);
            });
        } else {

        let fullCoords = latitude.toFixed(8) + "°<br>" + longitude.toFixed(8) + "°";

        // let dateString = gon.device_locations[0].received_at;

        let date = new Date(dateString);
        let unixTime = date.getTime();

        const formatDate = dateString.slice(0, 10);
        const formatTime = dateString.slice(11, 19);

        let elapsedTime = formatLastSeen(unixTime);

        // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
        const mapboxApi =
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
          longitude +
          "," +
          latitude +
          ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
        fetch(mapboxApi)
          .then((response) => response.json())
          .then((data) => {
            // Extract the place_name property and store it in a variable
            const placeName = data.features[0].place_name;

            // Popup
            let popup = new AnimatedPopup({
              anchor: "bottom",
              offset: 25,
              openingAnimation: {
                duration: 600,
                easing: "easeOutElastic",
                transform: "scale",
              },
              closingAnimation: {
                duration: 200,
                easing: "easeInBack",
                transform: "scale",
              },
            }).setHTML(
                `<h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
                <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
                <colgroup>
                <col style="width: 90px">
                <col style="width: 80px">
                </colgroup>
                <thead>
                  <tr>
                    <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal">Address:</span></th>
                    <th class="popup-nvirosense-73oq">${placeName}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
                    <td class="popup-nvirosense-73oq"><br>${formatDate}<br>${formatTime}</td>
                  </tr>
                  <tr>
                    <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
                    <td class="popup-nvirosense-73oq">${elapsedTime}</td>
                  </tr>
                  <tr>
                    <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
                    <td class="popup-nvirosense-73oq">${fullCoords}</td>
                  </tr>
                </tbody>
                </table>`
            );
            // End: Popup

            // Marker
            const marker = new mapboxgl.Marker()
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map);
            // End: Marker

            marker.getElement().addEventListener("click", function () {
              const lngLat = marker.getLngLat();

              map.flyTo({ center: [lngLat.lng, lngLat.lat + 0.011], zoom: 13 });
            });

            // popup.addTo(map).setLngLat([longitude, latitude]).addTo(map); // adds the popup on load

            console.log(placeName);
          })
          .catch((error) => {
            // Handle any errors that occur during the fetch request
            // console.error(error);
          });

        // End: API to GET adress
        }

      }

      function mapLogic(latitude, longitude) {
        if (latitude == 0 || longitude == 0) {
          mapLayer(mapStyle, -28.5, 25.541563, 5);
          marker.setLngLat([25.541563, -29.116376]).addTo(map);
        } else {
          mapLayer(mapStyle, latitude, longitude, 13);
          marker.setLngLat([longitude, latitude - 0.01]).addTo(map);
        }
      }

      let popup;
      let devicesData = [];
      let dateString;
      let deviceName;
      let deviceLastReceived;
      let deviceType;
      let latitude;
      let longitude;
      let map;
      var navControl = new mapboxgl.NavigationControl();
      let mapStyle = "mapbox://styles/mapbox/outdoors-v12";
      // var deviceId = document.getElementById('device-data').dataset.deviceId;
      let idPathArray = window.location.pathname.split("/");
      let groupId = idPathArray[2];
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;

      mapLayer(mapStyle, -28.5, 25.541563, 5);

      fetch(
        `/device_groups/${groupId}/group_map_locations`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          for (let i = 0; i < data.length; i++) {
            const device = data[i];

            if (device.drag_lat == 0 || device.drag_lon == 0) {
              continue;
            }

            // const marker = new mapboxgl.Marker();
            // marker.setLngLat([device.drag_lon, device.drag_lat - 0.01]).addTo(map);
            if (device.device_received_at == null){
              device.device_received_at = 'No Readings'
            }

            markerPopup(
              device.drag_lat,
              device.drag_lon,
              device.device_name,
              device.device_type,
              device.device_received_at
            );

            var jsonDevice = {
              device_name: device.device_name,
              drag_lat: device.drag_lat,
              drag_lon: device.drag_lon,
              device_type: device.device_type,
              device_received_at: device.device_received_at,
            };
            devicesData;

            devicesData.push(jsonDevice);

            // markerPopup(device.drag_lat, device.drag_lon, device.device_name, device.device_type //   deviceLastReceived
            // );

            console.log("check");
          }

          console.log(devicesData);

          // mapLogic(latitude, longitude);
          console.log("data");

          // Remove the navigation control from the map
          map.removeControl(navControl);

          // Add the navigation control to the map
          map.addControl(navControl);
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      // handle error
      console.log(error);
      // alert("error called");
    }
  }
}
