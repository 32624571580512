//import { formatLastSeen } from "../custom/functions/common.js";
// Function turns given time to unix to get lastseen
export function formatLastSeen(unixtimestamp) {
  const now = new Date();
  const diff = now - unixtimestamp;

  // Convert milliseconds to seconds, minutes, hours, or days as appropriate
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " days ago";
  } else if (hours > 0) {
    return hours + " hours ago";
  } else if (minutes > 0) {
    return minutes + " minutes ago";
  } else {
    return seconds + " seconds ago";
  }
}


// export function mapLayer(mapStyle, latitude, longitude, zoom, deviceType) {
//   // Create the map layer & style
//   const mapBoxToken =
//     "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

//   mapboxgl.accessToken = mapBoxToken;
//   const map = new mapboxgl.Map({
//     container: deviceType, // container ID
//     style: mapStyle, // style URL
//     center: [longitude, latitude], // starting position [lng, lat]
//     attribution: "Nvirosense",
//     zoom: zoom, // starting zoom
//     // pitch: 70,
//     cooperativeGestures: true,
//     attributionControl: false,
//   });

//   map.on("load", () => {
//     map.addSource("dem", {
//       type: "raster-dem",
//       url: "mapbox://mapbox.mapbox-terrain-dem-v1",
//     });
//     map.addLayer(
//       {
//         id: "hillshading",
//         source: "dem",
//         type: "hillshade",
//       },
//       // Insert below land-structure-polygon layer,
//       // where hillshading sits in the Mapbox Streets style.
//       "land-structure-polygon"
//     );
//   });

//   return map;
// }
