document.addEventListener('turbolinks:load', function() {
  $(document).on('change', '.device-type-select', function() {
    var selectedDeviceType = $(this).val();
    if (selectedDeviceType) {
      $.ajax({
        url: '/alarm_settings/render_partial',
        type: 'GET',
        data: { device_type: selectedDeviceType },
        success: function(response) {
          $('#partial-container').html(response);
        }
      });
    } else {
      $('#partial-container').empty();
    }
  });
});
