import consumer from "./consumer"

consumer.subscriptions.create("NotificationInSpecChannel", {
  received(data) {
    // console.log("Received:", data);
    // alert("made");

    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');

          new Notification(data.title, {
            body: data.body,
          });

        } else if (permission === 'denied') {
          console.log('Notification permission denied.');
        }
      });
    } else {
      console.log('This browser does not support notifications.');
    }

  }
});
